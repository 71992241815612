const codePreviewPlugin = store => {
    // TODO: https://kundigo.atlassian.net/browse/ODB-57
    store.subscribeAction({
        after: (action, state) => {

            if (action.type !== 'dataReceivedFromServer' ) {
                return;
            }

            let value, path, previousValue, previousTouched


            if ( action.payload?.component_definition )  {
                value = action.payload.component_definition.code_preview
                path = "component_definition[code_preview]"
                previousValue = store.getters.getValue(path)
                previousTouched = store.getters.getTouched(path)
            }

            if ( action.payload?.template )  {
                value = action.payload.template.code_preview
                path = "template[code_preview]"
                previousValue = store.getters.getValue(path)
                previousTouched = store.getters.getTouched(path)

            }



            if ( action.payload?.aktion_template )  {
                value = action.payload.aktion_template.code_preview
                path = "aktion_template[code_preview]"
                previousValue = store.getters.getValue(path)
                previousTouched = store.getters.getTouched(path)
            }


            if ( action.payload?.aktion )  {
                value = action.payload.aktion.code_preview
                path = "aktion[code_preview]"
                previousValue = store.getters.getValue(path)
                previousTouched = store.getters.getTouched(path)
            }

            if ( action.payload?.view_template )  {
                value = action.payload.view_template.code_preview
                path = "view_template[code_preview]"
                previousValue = store.getters.getValue(path)
                previousTouched = store.getters.getTouched(path)
            }


            if ( action.payload?.view )  {
                value = action.payload.view.code_preview
                path = "view[code_preview]"
                previousValue = store.getters.getValue(path)
                previousTouched = store.getters.getTouched(path)
            }


            if ( action.payload?.file_path_template )  {
                value = action.payload.file_path_template.code_preview
                path = "file_path_template[code_preview]"
                previousValue = store.getters.getValue(path)
                previousTouched = store.getters.getTouched(path)
            }


            if (value && value !== previousValue) {
                store.commit('setValue', {
                        value: value,
                        name: path
                    }
                )
                if (!previousTouched) {
                    store.commit('setTouched', {
                            value: true,
                            name: path
                        }
                    )
                }
            }

            if ( action.payload?.template )  {
                value = action.payload.template.create_automatically_condition_preview
                path = "template[create_automatically_condition_preview]"
                previousValue = store.getters.getValue(path)
                previousTouched = store.getters.getTouched(path)

                if (value && value !== previousValue) {
                    store.commit('setValue', {
                            value: value,
                            name: path
                        }
                    )
                    if (!previousTouched) {
                        store.commit('setTouched', {
                                value: true,
                                name: path
                            }
                        )
                    }
                }

            }
        }
    })
}

export default codePreviewPlugin